<template>
    <div>
        <FileUpload
            :multiple="true"
            :customUpload="true"
            @uploader="uploadImagem"
            chooseLabel="Selecionar Imagem"
            uploadLabel="Upload Imagem"
            cancelLabel="Limpar"
            @select="onSelectedFiles"
            ref="fileUpload"
            accept="image/*"
        >
            <template #empty>
                <div class="flex align-items-center justify-content-center flex-column">
                    <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                    <p class="mt-4 mb-0">Arraste e solte os arquivos aqui…</p>
                </div>
            </template>
        </FileUpload>
        <div v-if="arquivosExibir.length > 0">
            <div class="flex flex-wrap justify-content-start">
                <div
                    v-for="(file, index) of arquivosExibir"
                    :key="file.title"
                    class="card w-2 h-20rem flex flex-column align-items-center justify-content-between"
                    style="min-width: 20vh"
                >
                    <div>
                        <img
                            role="presentation"
                            style="display: block; width: 100px; height: 100px"
                            id="base64image"
                            :alt="file.title"
                            :src="imagesSrc[index]"
                            :title="file.title"
                            width="100"
                            class="shadow-2 m-1 white-space-nowrap"
                        />
                    </div>
                    <span :title="file.title" class="white-space-nowrap overflow-hidden text-overflow-ellipsis font-semibold" style="width: 150px">{{
                        file.title
                    }}</span>
                    <template v-if="!file.progress">
                        <div class="white-space-nowrap">
                            <Button label="Principal" class="btn mr-1" :disabled="file.principal" @click="principal(file)" />
                            <Button label="Banner" class="btn ml-1" :disabled="file.banner" @click="banner(file)" />
                        </div>
                        <Button icon="pi pi-trash" title="Excluir" @click="confirm(file)" class="p-button-danger p-button-rounded" />
                    </template>
                    <div class="md:col-8" v-else>
                        <ProgressBar :value="file.progress"></ProgressBar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceImagem from '../shared/serviceImagens';

export default {
    props: {
        classe: {
            type: Object,
        },
    },
    data() {
        return {
            uploadedFiles: [],
            files: [],
            arquivosLoading: [],
            imagesSrc: [],
        };
    },
    mounted() {
        this.obterArquivos();
    },
    computed: {
        arquivosExibir() {
            return this.arquivosLoading.concat(this.uploadedFiles);
        },
    },
    methods: {
        obterArquivos() {
            ServiceImagem.obterImagens(this.classe.id, this.classe.nome).then((response) => {
                if (response.success) {
                    this.uploadedFiles = response.data;
                    this.uploadedFiles.forEach((element, index) => {
                        this.fileThumb(element.arquivo.urlDownload, index);
                    });
                }
            });
        },
        onSelectedFiles(event) {
            if (this.classe.id) {
                this.files = [];
                event.files.forEach((element) => {
                    let template = {
                        id: this.classe.id,
                        classe: this.classe.nome,
                        title: element.name,
                        imagem: true,
                        arquivo: element,
                    };
                    this.files.push(template);
                });
            }
        },

        uploadImagem() {
            let uploaded = 0;
            this.files.forEach((element) => {
                ServiceImagem.uploadImagem(element, (loaded, total) => this.onUploadProgress(loaded, total, element)).then((response) => {
                    if (response?.success) {
                        uploaded++;
                    }
                    if (uploaded == this.files.length) {
                        this.arquivosLoading = [];
                        this.obterArquivos();
                    }
                });
                this.arquivosLoading.push(element);
            });
            this.$refs.fileUpload.files = null;
        },
        onUploadProgress(loaded, total, element) {
            element.progress = Math.floor((loaded * 100) / total);
        },
        deletarArquivo(arquivo) {
            ServiceImagem.excluirImagem(arquivo.uuid).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Exclusão de Arquivo',
                        detail: 'Arquivo excluído com sucesso',
                        life: 3000,
                    });
                    this.obterArquivos();
                }
            });
        },
        banner(arquivo) {
            arquivo.banner = true;
            ServiceImagem.setBannerPrincipal(arquivo.arquivo.uuid, arquivo).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Banner',
                        detail: 'Banner selecionado com sucesso',
                        life: 3000,
                    });
                    this.obterArquivos();
                }
            });
        },
        principal(arquivo) {
            arquivo.principal = true;
            ServiceImagem.setBannerPrincipal(arquivo.arquivo.uuid, arquivo).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Imagem Principal',
                        detail: 'Imagem Principal selecionada com sucesso',
                        life: 3000,
                    });
                    this.obterArquivos();
                }
            });
        },
        fileThumb(url, index) {
            ServiceImagem.getThumb(url).then((response) => {
                if (response?.success) {
                    let buffer = Buffer.from(response.data).toString('base64');
                    let img = document.createElement('img');
                    img.src = `data:image/png;base64, ${buffer}`;
                    this.imagesSrc[index] = img.src;
                }
            });
        },
        confirm(arquivo) {
            this.$confirm.require({
                message: `Você deseja excluir a imagem?`,
                header: 'Confirmação de Exclusão',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deletarArquivo(arquivo.arquivo);
                },
            });
        },
    },
};
</script>

<style >
.btn:disabled {
    background: green;
    border: 0ch;
}
.btn {
    background: grey;
    border: 0ch;
}
</style>

import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;

const urlBase = `${api}/arquivo`;

const urlImagem = `${urlBase}/imagem`;

export default {
    uploadImagem(template,  onUploadProgress) {
        const formDataType = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                onUploadProgress(loaded, total)
            }
        
        };

        const formData = new FormData();
        formData.append('id', template.id)
        formData.append('classe', template.classe)
        formData.append('title', template.title)
        formData.append('imagem', template.imagem)
        formData.append('file', template.arquivo)
        return axiosJwt.post(`${urlBase}/upload/imagem/${template.classe}/${template.id}/${template.title}`, formData, formDataType);
    },
    
    obterImagens(id, classe, active = true) {
        return axiosJwt.get(`${urlImagem}/${classe}/${id}?active=${active}`)
    },

    setBannerPrincipal(uuid, imagem) {
        return axiosJwt.patch(`${urlImagem}/${uuid}`, imagem);

    },

    excluirImagem(uuid) {
        return axiosJwt.delete(`${urlImagem}/${uuid}`);
    },

    getThumb(url) {
        return axiosJwt.get(url, {
            responseType: 'arraybuffer',
        });
    },

    obterImagemPrincipal(id, classe, active = true) {
        return axiosJwt.get(`${urlImagem}/${classe}/${id}/principal?active=${active}`)
    }
};

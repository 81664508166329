<template>
    <div class="card">
        <div class="flex justify-content-center">
            <h5>Solução Imagens</h5>
        </div>
        <imagens :classe="classe" />
    </div>
</template>

<script>
import Imagens from '../components/Imagens.vue';

export default {
    components: { Imagens },
    data() {
        return {
            classe: {
                id: this.$route.params.id,
                nome: "Solucao",
            },
        };
    },
}
       
</script>

<style></style>
